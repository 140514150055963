import React, { useState } from 'react';
import '../scss/creditScore.scss';

const CreditScore = () => {
  return (
    <main>
      <section className="site-wrap">
        <h5>
          Sequin partners with StitchCredit to give you a free credit score and
          report. Check this as much as you want; this won&apos;t impact your
          credit score!
        </h5>
        <div className="stitch-wrapper">
          <iframe
            title="Stitch Credit score checker"
            src="https://efx-wgt.stitchcredit.com/api/users/start?key=60afbfc4-da0b-4e07-8e14-a2961ec80f5e"
            width="100%"
            height="100%"
          />
        </div>
      </section>
    </main>
  );
};

export default CreditScore;
